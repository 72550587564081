<script lang="ts">
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { inertia } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    import { DateTime } from 'luxon';
    import { Banknotes, Icon, Sparkles, Calendar } from 'svelte-hero-icons';
    import ApexCharts from 'apexcharts';
    import axios from 'axios';
    import type { DateRange } from 'bits-ui';
    import { CalendarDate, DateFormatter, type DateValue, getLocalTimeZone } from '@internationalized/date';
    import { cn } from '$lib/utils.js';
    import { Button } from '$lib/components/ui/button/index.js';
    import { RangeCalendar } from '$lib/components/ui/range-calendar/index.js';
    import * as Popover from '$lib/components/ui/popover/index.js';
    export let cookies: string;
    export let currentUserId: string;
    export let withdrawalPoints: number;

    console.log('current user', currentUserId);

    let currentRange: any = { start: null, end: null };
    let formattedRange: { start: string | null; end: string | null } = { start: null, end: null };
    let scale: string = 'd';
    let source: string = 'post';
    let amount: number = 7;
    let offset: number = 0;
    let currentRoute = '';
    let chartContainer: HTMLElement | null = null;
    let chart: any;
    let selectedTimeframe: string = 'Last 7 Days';
    let timeframeValue: string = 'sevenDays';
    let revenuePeriodStats: string = '';
    let revenuePeriodValue: number = 0;
    let revenuePeriodPercent: string = '';
    let revenuePeriodPercentColor: string = '';
    let revenuePeriodPercentBg: string = '';
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthNumber = currentDate.getMonth();
    const prevYear = currentYear - 1;
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1).toLocaleString('default', {
        month: 'long',
    });
    const prevPMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1).toLocaleString('default', {
        month: 'long',
    });
    const getMonthRange = (year: number, month: number) => {
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        return { start: firstDay, end: lastDay };
    };
    const currentMonthRange = getMonthRange(currentYear, currentMonthNumber);
    const prevMonthRange = getMonthRange(currentYear, currentMonthNumber - 1);
    const monthbeforePrevRange = getMonthRange(currentYear, currentMonthNumber - 2);
    const formatDate = (date: Date) => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
    const df = new DateFormatter('en-US', {
        dateStyle: 'medium',
    });

    const predefinedTimes = () => {
        let startDate: string, endDate: string;

        switch (timeframeValue) {
            case 'currentYear':
                startDate = `${currentYear}-01-01`;
                endDate = `${currentYear}-12-31`;
                break;
            case 'prevYear':
                startDate = `${prevYear}-01-01`;
                endDate = `${prevYear}-12-31`;
                break;
            case 'currentMonth':
                startDate = formatDate(currentMonthRange.start);
                endDate = formatDate(currentMonthRange.end);
                break;
            case 'prevMonth':
                startDate = formatDate(prevMonthRange.start);
                endDate = formatDate(prevMonthRange.end);
                break;
            case 'prevPMonth':
                startDate = formatDate(monthbeforePrevRange.start);
                endDate = formatDate(monthbeforePrevRange.end);
                break;
            default:
                startDate = `${currentYear}-01-01`;
                endDate = `${currentYear}-12-31`;
                break;
        }

        updateRangeAndChart(startDate, endDate);
    };

    const updateTimeframe = (selectedValue: string, selectedLabel: string) => {
        selectedTimeframe = selectedLabel;
        timeframeValue = selectedValue;

        switch (selectedValue) {
            case 'sevenDays':
                scale = 'd';
                amount = 7;
                break;
            case 'oneMonth':
                scale = 'd';
                amount = 30;
                break;
            case 'threeMonths':
                scale = 'd';
                amount = 90;
                break;
            case 'oneYear':
                scale = 'm';
                amount = 12;
                break;
            default:
                scale = 'd';
                amount = 7;
                break;
        }

        handleItemChange();
    };

    const updateRangeAndChart = (startDate: string, endDate: string) => {
        formattedRange.start = startDate;
        formattedRange.end = endDate;
        currentRange.start = new Date(startDate + 'T00:00:00');
        currentRange.end = new Date(endDate + 'T23:59:59');
        updateChartCustom();
    };

    const handleItemChange = () => {
        if (timeframeValue === 'custom') {
            if (currentRange.start && currentRange.end) {
                updateChartCustom();
            }
        } else {
            if (
                timeframeValue === 'currentYear' ||
                timeframeValue === 'prevYear' ||
                timeframeValue === 'currentMonth' ||
                timeframeValue === 'prevMonth' ||
                timeframeValue === 'prevPMonth'
            ) {
                predefinedTimes();
            } else {
                updateChart();
            }
        }
    };

    onMount(() => {
        currentRoute = window.location.pathname;

        if (chartContainer) {
            chart = new ApexCharts(chartContainer, {
                series: [{ data: [] }],
                chart: {
                    height: 450,
                    type: 'line',
                    zoom: { enabled: false },
                },
                annotations: {
                    yaxis: [
                        {
                            y: 8200,
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                style: { color: '#fff', background: '#00E396' },
                                text: 'Support',
                            },
                        },
                    ],
                },
                dataLabels: { enabled: false },
                stroke: { curve: 'straight' },
                grid: {
                    row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 },
                },
                xaxis: {
                    type: 'category',
                    categories: [],
                    labels: {
                        format: 'dd.MM.',
                    },
                },
                yaxis: { opposite: true },
                legend: { horizontalAlign: 'left' },
            });
            chart.render();
        }
    });

    const timeframe = [
        { value: 'sevenDays', label: 'Last 7 Days' },
        { value: 'oneMonth', label: 'Last Month' },
        { value: 'threeMonths', label: 'Last 3 Month' },
        { value: 'oneYear', label: 'Last Year' },
        { value: 'currentYear', label: currentYear.toString() },
        { value: 'prevYear', label: prevYear.toString() },
        { value: 'currentMonth', label: currentMonth },
        { value: 'prevMonth', label: prevMonth },
        { value: 'prevPMonth', label: prevPMonth },
        { value: 'custom', label: 'Custom' },
    ];

    const statsUpdate = (stats: number, percent: number, pointsDisplay = false) => {
        let percentround = Math.ceil(percent);

        if (pointsDisplay) {
            revenuePeriodValue = Math.ceil(stats * withdrawalPoints);
        } else {
            revenuePeriodValue = Math.ceil(stats);
        }

        let color = percent > 0 ? '#00FFA3' : percent < 0 ? '#ff0000' : 'rgba(128, 128, 128, 1)';
        let backgroundColor = percent < 0 ? 'rgba(255, 20, 64, 0.12)' : percent > 0 ? '' : 'rgba(117,117,117,0.12)';
        const sign = percent > 0 ? '+' : '';

        revenuePeriodPercent = `${sign}${percentround}%`;
        revenuePeriodPercentColor = color;
        revenuePeriodPercentBg = backgroundColor;
    };

    const processChartData = (
        data: {
            data: { [x: string]: any[]; bid: any[]; deposit: any[]; reward: any[]; subscription: any[]; tip: any[] };
        },
        toggleState: boolean
    ) => {
        const multiplier = toggleState ? 1 : withdrawalPoints;
        return {
            bid: data.data.bid.map((value) => value * multiplier),
            deposit: data.data.deposit.map((value) => value * multiplier),
            postUnlock: data.data['post-unlock'].map((value) => value * multiplier),
            messageUnlock: data.data['message-unlock'].map((value) => value * multiplier),
            reward: data.data.reward.map((value) => value * multiplier),
            subscription: data.data.subscription.map((value) => value * multiplier),
            tip: data.data.tip.map((value) => value * multiplier),
        };
    };

    const showLoaders = () => {
        document.getElementById('chartLoader')?.classList.remove('hidden');
        document.getElementById('valueLoader')?.classList.remove('hidden');
    };

    const hideLoaders = () => {
        document.getElementById('chartLoader')?.classList.add('hidden');
        document.getElementById('valueLoader')?.classList.add('hidden');
    };

    const updateChart = async () => {
        revenuePeriodStats = `${amount}${scale}`;

        let apiUrl = `/analytics/revenue/v1?scale=${scale}&amount=${amount}&offset=${offset}`;

        showLoaders();

        try {
            const response = await axios.get(apiUrl);
            const data = response.data;
            hideLoaders();

            const toggleState = (document.querySelector('[data-points-toggle]') as HTMLInputElement)?.checked ?? false;

            statsUpdate(data.last, data.growth, !toggleState);

            const processedData = processChartData(data, toggleState);

            chart?.updateSeries([
                { name: 'Bid', data: processedData.bid },
                { name: 'Deposit', data: processedData.deposit },
                { name: 'Post Unlock', data: processedData.postUnlock },
                { name: 'Message Unlock', data: processedData.messageUnlock },
                { name: 'Reward', data: processedData.reward },
                { name: 'Subscription', data: processedData.subscription },
                { name: 'Tip', data: processedData.tip },
            ]);
        } catch (error) {
            hideLoaders();
            console.error('Error Fetching Data', error);
        }
    };

    const updateChartCustom = async () => {
        revenuePeriodStats = `${formattedRange.start} - ${formattedRange.end}`;

        if (currentRange.start && currentRange.end) {
            let apiUrl = `/analytics/revenue/v1?start_date=${encodeURIComponent(currentRange.start.toISOString())}&end_date=${encodeURIComponent(currentRange.end.toISOString())}`;

            showLoaders();

            try {
                const response = await axios.get(apiUrl);
                const data = response.data;
                hideLoaders();

                const toggleState =
                    (document.querySelector('[data-points-toggle]') as HTMLInputElement)?.checked ?? false;

                statsUpdate(data.last, data.growth, !toggleState);

                const processedData = processChartData(data, toggleState);

                chart?.updateSeries([
                    { name: 'Bid', data: processedData.bid },
                    { name: 'Deposit', data: processedData.deposit },
                    { name: 'Post Unlock', data: processedData.postUnlock },
                    { name: 'Message Unlock', data: processedData.messageUnlock },
                    { name: 'Reward', data: processedData.reward },
                    { name: 'Subscription', data: processedData.subscription },
                    { name: 'Tip', data: processedData.tip },
                ]);
            } catch (error) {
                hideLoaders();
                console.error('Error Fetching Data', error);
            }
        }
    };

    const handleDateChange = (range: any) => {
        if (range?.start && range?.end) {
            currentRange.start = range.start.toDate(getLocalTimeZone());
            currentRange.end = range.end.toDate(getLocalTimeZone());

            formattedRange.start = DateTime.fromJSDate(currentRange.start).toFormat('yyyy-MM-dd');
            formattedRange.end = DateTime.fromJSDate(currentRange.end).toFormat('yyyy-MM-dd');

            updateChartCustom();
        }
    };

    // function onDateChange(event: CustomEvent<{ detail: DateRange }>) {
    //     handleDateChange(event.detail);
    // }

    let value: DateRange = {
        start: new CalendarDate(currentYear, currentMonthNumber + 1, 1),
        end: new CalendarDate(currentYear, currentMonthNumber + 1, 30),
    };

    let startValue: DateValue | undefined = undefined;
</script>

<div class="min-vh-100 pr-0 pt-4">
    <div class="px-4">
        <h5 class="text-truncate mb-0 font-bold dark:text-white {cookies}">Analytics Dashboard</h5>
    </div>

    <div class="inline-border-tabs mb-1">
        <nav class="nav nav-pills nav-justified text-bold mt-3">
            <a
                class="nav-item flex gap-x-1 items-center justify-center nav-link {currentRoute === '/analytics/engagement' ? 'active' : ''}"
                href="/analytics/engagement"
                use:inertia
            >
                <Icon src="{Sparkles}" size="22px" />
                Engagement
            </a>
            <a
                class="nav-item flex gap-x-1 items-center justify-center nav-link {currentRoute === '/analytics/revenue' ? 'active' : ''}"
                href="/analytics/revenue"
                use:inertia
            >
                <Icon src="{Banknotes}" size="22px" />
                Revenue
            </a>
        </nav>
    </div>

    <div class="px-2 pt-3">
        <div class="impression mt-4 flex items-center">
            <div class="analytic-type">
                <DropdownMenu.Root>
                    <DropdownMenu.Trigger class="px-4 py-2 rounded-md bg-neutral-100 dark:bg-neutral-800 flex">{selectedTimeframe}</DropdownMenu.Trigger>
                    <DropdownMenu.Content>
                        <DropdownMenu.Group>
                            {#each timeframe as time}
                                <DropdownMenu.Item on:click="{() => updateTimeframe(time.value, time.label)}">
                                    {time.label}
                                </DropdownMenu.Item>
                            {/each}
                        </DropdownMenu.Group>
                    </DropdownMenu.Content>
                </DropdownMenu.Root>
            </div>

            <div class="analytic-type ml-4 flex items-center">
                &nbsp;
                <label class="switch mb-0">
                    <input class="switch__input" type="checkbox" data-points-toggle />
                    <span class="switch__slider"></span>
                </label>
                &nbsp;
                <span class="font-mona-expanded text-lg font-bold text-black dark:!text-white">$</span>
            </div>

            {#if timeframeValue === 'custom'}
                <div class="date-ranger-picker">
                    <Popover.Root openFocus>
                        <Popover.Trigger asChild let:builder>
                            <Button
                                variant="outline"
                                class="{cn(
                                    'w-[300px] justify-start text-left font-normal',
                                    !value && 'text-muted-foreground'
                                )}"
                                builders="{[builder]}"
                            >
                                <Icon src="{Calendar}" class="mr-2 h-4 w-4" />
                                {#if value && value.start}
                                    {#if value.end}
                                        {df.format(value.start.toDate(getLocalTimeZone()))} - {df.format(
                                            value.end.toDate(getLocalTimeZone())
                                        )}
                                    {:else}
                                        {df.format(value.start.toDate(getLocalTimeZone()))}
                                    {/if}
                                {:else if startValue}
                                    {df.format(startValue.toDate(getLocalTimeZone()))}
                                {:else}
                                    Pick a date
                                {/if}
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content class="w-auto p-0" align="start">
                            <RangeCalendar
                                bind:value
                                bind:startValue
                                initialFocus
                                numberOfMonths="{2}"
                                placeholder="{value?.start}"
                            />
                        </Popover.Content>
                    </Popover.Root>
                </div>
            {/if}
        </div>

        <div class="stats mt-3">
            <span class="revenue-period-stats !flex !items-center text-lg text-black dark:!text-white">
                {revenuePeriodStats}
            </span>

            <div class="d-flex stats-values relative items-center">
                <div id="valueLoader" class="absolute bottom-0 left-0 top-0 z-50 hidden w-20 rounded-lg">
                    <div class="h-full w-full animate-pulse">
                        <div class="h-full w-full rounded-lg bg-gray-200"></div>
                    </div>
                </div>

                <span class="revenue-period-value font-mona-expanded text-6xl font-black text-black dark:!text-white">
                    {revenuePeriodValue}
                </span>
                &nbsp;&nbsp;&nbsp;
                <span
                    class="revenue-period-percent text-lg text-teal-400"
                    style="background-color: {revenuePeriodPercentBg}; color: {revenuePeriodPercentColor};"
                >
                    {revenuePeriodPercent}
                </span>
            </div>
        </div>
        <div id="chart-container" style="position: relative; top: 4px">
            <div id="chartLoader" class="absolute bottom-0 left-0 right-0 top-0 z-50 hidden !h-full !w-full rounded-lg">
                <div class="h-full w-full animate-pulse">
                    <div class="!h-full !w-full rounded-lg bg-gray-200"></div>
                </div>
            </div>
            <div>
                <div bind:this="{chartContainer}" id="chart"></div>
            </div>
        </div>
    </div>
</div>
